import React, { useState, useRef } from "react";
import trainingImage from "../../assets/images/traing.jpg";

import "./careers.css";

export const CareersPage: React.FC = () => {
  const jobsRef = useRef<HTMLDivElement>(null);

  const handleExploreClick = () => {
    if (jobsRef.current) {
      jobsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleApplyNowClick = () => {
    window.open("https://docs.google.com/forms/d/1j4kmao6MrxCynYcYyD0mDLoVappcUVrCjBa3pnGCj1U/edit", "_blank");
  };

  return (
    <div className="careers-page-container">
      {/* Hero Section */}
      <div className="careers-hero-section-container">
        <h1 className="careers-hero-title">Join The Technology Revolution</h1>
        <p className="careers-hero-description">
          Be a part of AspenCask and build the future with us, working on cutting-edge technologies.
        </p>
        <button className="careers-hero-explore-button" onClick={handleExploreClick}>
          Explore Training
        </button>
      </div>

      {/* Training Info Section */}
      <div className="careers-info-section-container">
        <h2 className="careers-info-title">Training for Freshers</h2>
        <p className="careers-info-description">
          Join our training program to kickstart your career in technology. Learn from industry experts and gain hands-on experience.
        </p>
      </div>

      {/* Training Section */}
      <div id="jobs-section" ref={jobsRef} className="careers-job-listings-container">
        <div className="careers-job-card">
          <div className="careers-job-header">
            <img src={trainingImage} alt="Training" className="careers-job-image" />
            <h2 className="careers-job-title">Training Program</h2>
          </div>
          <p className="careers-job-description"><strong>Description:</strong> Comprehensive training program for freshers.</p>
          <p className="careers-job-salary"><strong>Duration:</strong> 6 months</p>
          <p className="careers-job-experience"><strong>Experience:</strong> Freshers</p>
          <p className="careers-job-education"><strong>Education:</strong> Bachelor’s in Computer Science or relevant field</p>
          <p className="careers-job-skills"><strong>Skills Covered:</strong> Java, React, DevOps, Node.js and more</p>
          <button className="careers-job-apply-button" onClick={handleApplyNowClick}>
            Apply Now
          </button>
        </div>
      </div>
    </div>
  );
};
